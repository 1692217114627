import React from "react";
import { Helmet } from "react-helmet";

export default function PracticePage() {
  return (
    <>
      <Helmet>
        <title>Из практики адвоката</title>
        <meta property="og:title"
          content="Из практики адвоката" />
        <meta name="description"
          content="Случаи из практики адвоката в различных сферах. Свердловская, Челябинская, Курганская область и другие регионы России." />
        <link rel="canonical" href="/practice" />
      </Helmet>

      <div className="div-center">
        <article className="max-width-content">
          <h2>Из практики адвоката</h2>
          <section>
            <h5>РЕШЕНИЕ ПО ЗЕМЕЛЬНОМУ ДЕЛУ (гражданское).</h5>
            <p>Для того, кто имеет в собственности земельный участок, полученный еще в 1990-ые либо 2000-ые годы для строительства жилого дома, ведения приусадебного хозяйства и т.п., может быть актуальна вот такая ситуация.</p>
            <p>Ко мне обратилась П. с проблемой по имеющемуся у нее в собственности земельному участку, полученному еще в 1991г. для строительства жилого дома.</p>
            <p>Официально П. в собственность был передан участок площадью 12 соток (0,12 га), но ведь как тогда намеряли - чуть ли не «на глазок». И вот в 2019г. работниками в сфере надзора по земельному законодательству было выяснено, что земельный участок, который использует П., на самом деле имеет площадь 17 соток, т.е. часть земельного участка (5 соток) занимается П. не законно, и вообще, эти 5 соток уже официально включены в другой земельный участок, которым владеет иное (юридическое) лицо - Управление городским хозяйством.</p>
            <p>Как выяснилось, работники Управления Роснедвижимости, которые проводили в 2009г. кадастровые работы на участке П., официально намеряли и передали на регистрацию совершенно другие границы ее участка, несовпадающие с действительными.</p>
            <p>В отношении П. было возбуждено административное дело по самозахвату земли, а также вынесено предписание освободить лишние занимаемые ею 5 соток.</p>
            <p>Пришлось пройти и через судебное производство по административному делу, и инициировать гражданское судебное дело – решили обратиться в суд с иском об уточнении границ и площади земельного участка, с признанием кадастровых работ 2009г. недействительными.</p>
            <p>При рассмотрении гражданского дела удалось доказать, что действующее законодательство позволяет оставлять в собственности излишне намеренную площадь (но не более минимального размера земельного участка, выделяемого в собственность гражданам в данном муниципальном образовании), и что кадастровые работы в 2009г. были проведены как с нарушением прежних, так и новых норм закона.</p>
            <p>Суд удовлетворил заявленные нами исковые требования, согласившись, что необходимо внести уточненные сведения в ЕГРН о площади и границах земельного участка, исходя из границ, действительно существующих на местности.</p>
            <i><p>Выдержка из решения Синарского районного суда г.Каменска-Уральского от 06.09.2019г. : « исковое заявление П. к органу местного самоуправления «Комитет по управлению имуществом г. Каменска-Уральского» о признании недействительными результатов кадастровых работ, установлении границы земельных участков – удовлетворить.</p>
              <p>Признать недействительными результаты кадастровых работ, произведенных в отношении земельного участка с кадастровым номером №, расположенного в д. &#60;адрес&#62;, в части установления смежной границы с земельным участком с кадастровым номером №, в рамках межевого плана № от 24.07.2009, подготовленного кадастровым инженером ООО «&#60;*****&#62;» ФИО4</p>
              <p>Установить границу между земельными участками с кадастровыми номерами № и № в координатах, указанных в межевом плане кадастрового инженера ФИО от 11.04.2019: …..</p>
              <p>Настоящее решение является основанием для внесения изменений в Единый государственный реестр недвижимости…»</p></i>
            <p>Таким образом, через судебные органы удалось добиться защиты прав клиента, и теперь П. владеет своим земельным участком площадью 17 соток уже на законном основании, что обезопасит ее от необоснованных притязаний различных служб и ведомств в дальнейшем.</p>
          </section>
          <section>
            <h5>РЕШЕНИЕ ПО ДЕЛУ О НАРКОТИКАХ (уголовное).</h5>
            <p>Нередко обращаются за помощью – защитой при уголовном преследовании лиц в сфере незаконного оборота наркотических средств.</p>
            <p>Дела этой категории делится на два вида: незаконные приобретение, хранение и т.д. без цели сбыта (т.е. для себя) и сбыт наркотических средств.</p>
            <p>Поговорим о первом, наиболее легком, виде преступлений.</p>
            <p>Нужно сказать, что судебная практика по назначению наказания за приобретение и хранение наркотических средств не постоянна. При этом, конечно, вид и размер наказания сильно зависит от веса изъятого наркотика, что определяет уголовное преследование по 1-ой или 2-ой части статьи 228 УК РФ, т.е. относит совершенное преступление к той или иной категории тяжести, что в свою очередь уменьшает или увеличивает шанс остаться на свободе при вынесении приговора.</p>
            <p>Суд смотрит и на наличие-отсутствие прежних судимостей, времени, прошедшего с момента отбытия наказания по прежнему преступлению, на личность виновного лица – наличие работы, несовершеннолетних детей, заболеваний, различных характеристик и т.д.</p>
            <p>Ко мне обратился З., которого привлекли к уголовной ответственности по ч.2ст.228 УК РФ, и имелась опасность получить наказание в виде реального лишения свободы на срок до 10 лет.</p>
            <p>Он приобрел через интернет-магазин так называемую «соль», наркотическое средство, являющееся производным вещества N-метилэфедрон, приехал в указанное место, нашел «закладку» с купленным товаром, и решил сразу употребить его часть путем курения, в ходе чего и был задержан работниками полиции.</p>
            <p>С подзащитным была выработана позиция, определены сведения, которые стоит сообщить и которые не стоит сообщать следственным органам. В уголовное дело был представлен исчерпывающий характеризующий положительно З. материал.</p>
            <p>При рассмотрении уголовного дела в судебном заседании 05.03.2020г., кроме прочего было обращено внимание суда на тот факт, что подсудимый часть приобретенного наркотика смешал с табаком сигареты для дальнейшего употребления, и именно это привело к увеличению веса всей смеси до превышения порога крупного размера (1грамм), что и повлекло квалификацию действий подсудимого уже не по преступлению небольшой тяжести, а по тяжкому преступлению.</p>
            <p>Фактически же З. было совершено преступление меньшей общественной опасности, чем ему оказалось вменено по уголовному делу.</p>
            <p>Суд учел все обстоятельства и назначил наказание минимально возможное по делу, не связанное с лишением свободы.</p>
            <p>Семья не лишилась своего близкого, родного человека, З. смог находясь на свободе и продолжая нормальную социальную жизнь подтвердить, что не нуждается для своего исправления в таком строгом наказании, как изоляция от общества.</p>
          </section>
          <section>
            <h5>РЕШЕНИЕ ПО ДЕЛУ ОБ АДМИНИСТРАТИВНОМ ПРАВОНАРУШЕНИИ.</h5>
            <p>Те, кто имеют во владении автомобили, частенько вынуждены сталкиваться с порой предвзятыми действиями работников ГИБДД.</p>
            <p>Ко мне обратился за помощью Б., попавший в такую вот ситуацию.</p>
            <p>Он уехал в выходной день на своем автомобиле на дачу, расположенную в одном из сел Каменского района.</p>
            <p>Поскольку уже не собирался садиться за руль, то позволил себе выпить 0,5л. пива. Затем занимался делами по хозяйству, строительством сарая. Однако, вечером возникла настоятельная потребность вернуться домой по семейным причинам.</p>
            <p>К тому моменту уже прошло около 10 часов, как Б. употребил спиртное, и чувствовал он себя как обычно, в трезвом состоянии, поэтому Б. решил вернуться домой на автомобиле.</p>
            <p>Однако, на въезде в город он был остановлен нарядом ГИБДД. Работник ГИБДД проверил его документы и предложил пройти освидетельствование на опьянение при помощи алкотектора. Б. согласился, но при проведении испытаний, прибор показал 0,18мг/л наличие паров этанола в выдыхаемом воздухе.</p>
            <p>Б. не согласился с результатами проведенных испытаний и потребовал проведение медицинского освидетельствования на состояние опьянения.</p>
            <p>Работники ГИБДД долго составляли документы, оформляли на эвакуатор автомобиль, а Б. все ждал, когда его отправят на мед.освидетельствование, но так и не дождался – работники ГИБДД собрались и уехали с места его остановки.</p>
            <p>Б. был привлечен мировым судьей к административной ответственности по ст.12.8ч.1 КоАП РФ - управление транспортным средством водителем, находящимся в состоянии опьянения, с лишением прав управления и штрафом.</p>
            <p>Была подана жалоба в вышестоящий суд, где все же удалось доказать, что были нарушены права водителя на проведение медицинского освидетельствования на состояние опьянения, а показания алкотектора, с учетом его погрешности, не позволяют прийти к выводу о нахождении испытуемого в состоянии алкогольного опьянения.</p>
            <p>Производство по делу об административном правонарушении было прекращено.</p>
          </section>
        </article>
      </div>
    </>
  );
}