import React from "react";
import { Helmet } from "react-helmet";

export const services = [
  {
    id: 'bankruptcy-of-individuals',
    value: 'Банкротство физических лиц',
    element: <>
      <Helmet>
        <title>Банкротство физических лиц</title>
        <meta property="og:title"
          content="Банкротство физических лиц" />
        <meta name="description"
          content="Очень многие из нас имеют займы и кредиты, долги перед банками и другими кредитными организациями, которые необходимо погашать, т.к. для приобретения автомобилей, жилья или по другим причинам были вынуждены воспользоваться заемными банковскими средствами. К сожалению, особенно в силу последних событий в мире, отразившихся и на нашей стране, многие россияне оказались лишены возможности производить выплаты по банковским долгам. По статистике, долги перед банками на начало 2020г. имело 26% россиян, а сейчас количество должников многократно возросло. На начало 2020г. общий долг россиян только по жилищному кредитованию составил более 8 млрд рублей, а общая сумма просроченной задолженности россиян перед банками превышает 1 триллион рублей !" />
        <link rel="canonical" href="/services/bankruptcy-of-individuals" />
      </Helmet>
      <div className="div-center">
        <div className="max-width-content">
          <section>
            <h3>Банкротство физических лиц</h3>
            <p>Очень многие из нас имеют займы и кредиты, долги перед банками и другими кредитными организациями, которые необходимо погашать, т.к. для приобретения автомобилей, жилья или по другим причинам были вынуждены воспользоваться заемными банковскими средствами.</p>
            <p>К сожалению, особенно в силу последних событий в мире, отразившихся и на нашей стране, многие россияне оказались лишены возможности производить выплаты по банковским долгам.</p>
            <p>По статистике, долги перед банками на начало 2020г. имело 26% россиян, а сейчас количество должников многократно возросло. На начало 2020г. общий долг россиян только по жилищному кредитованию составил более 8 млрд рублей, а общая сумма просроченной задолженности россиян перед банками превышает 1 триллион рублей !</p>
            <p><b>Что делать, если выплаты банкам стали для Вас непосильной ношей? Если Вы уже задыхаетесь от безысходности и тяжести кредитного бремени на ваших плечах ?</b></p>
            <p><i>Закон предусматривает для таких случаев процедуру банкротства, которая может стать для Вас спасением от той финансовой кабалы, в которую Вас загнали обстоятельства !</i></p>
            <p>Адвокат по банкротству подробно разберется в Вашей ситуации и поможет Вам избавиться от долговых обязательств перед банками !</p>
          </section>
        </div>
      </div>
    </>
  },
  {
    id: 'criminal-cases',
    value: 'Уголовные дела',
    element: <>
    <Helmet>
      <title>Уголовные дела</title>
      <meta property="og:title"
        content="Уголовные дела" />
      <meta name="description"
        content="Вызов к дознавателю, следователю или в суд по обвинению в уголовном преступлении приходит всегда неожиданно. Порой человека принудительно доставляют в правоохранительные органы и настойчиво требуют признательных показаний, даже если он не совершал данного преступления. Человек, попавший под обвинение в совершении преступления, обычно находится в шоковом состоянии и испытывает большой стресс. Таким положением вещей нередко пользуются работники полиции. Добиваясь признания, следователи могут применять приёмы психологического и физического давления, а также другие непопулярные и даже противозаконные методы. В подобных условиях человеку очень нужен верный совет, твердая поддержка со стороны, чтобы противостоять обстоятельствам не в одиночку." />
      <link rel="canonical" href="/services/criminal-cases" />
    </Helmet>
    <div className="div-center">
      <div className="max-width-content">
        <section>
          <h3>Уголовные дела</h3>
          <p>Вызов к дознавателю, следователю или в суд по обвинению в уголовном преступлении приходит всегда неожиданно. Порой человека принудительно доставляют в правоохранительные органы и настойчиво требуют признательных показаний, даже если он не совершал данного преступления.</p>
          <p><i>Человек, попавший под обвинение в совершении преступления, обычно находится в шоковом состоянии и испытывает большой стресс.</i></p>
          <p>Таким положением вещей нередко пользуются работники полиции. Добиваясь признания, следователи могут применять приёмы психологического и физического давления, а также другие непопулярные и даже противозаконные методы.</p>
          <p><i>В подобных условиях человеку очень нужен верный совет, твердая поддержка со стороны, чтобы противостоять обстоятельствам не в одиночку.</i></p>
          <p>Помощь адвоката по уголовным делам является просто жизненно необходимой и даже решающей. Профессиональный взгляд на сложившуюся ситуацию и знание законодательных нюансов помогут занять правильную позицию по делу и противостоять натиску со стороны правоохранительных органов, сформировать пакет веских аргументов для смягчения или отмены наказания, оправдания обвиняемого, подсудимого и оказать моральную поддержку подзащитному.</p>
          <p>Каждый случай индивидуален. Причем, выбор правильной позиции защиты не всегда очевиден с первого взгляда, и приходится вновь и вновь изучать материалы дела в поисках нужной информации. Это намного больше, чем рутинная работа. Это защита реальных людей, оказавшихся в сложной жизненной ситуации.</p>
          <p><i>Адвокат по уголовным делам имеет право вступить в судебный процесс на любой стадии его производства.</i></p>
          <p><u>К юридическим услугам в сфере уголовного права относятся:</u></p>
          <ul>
            <li>консультационно-правовая помощь</li>
            <li>выезд на место задержания, следственного действия</li>
            <li>участие при проведении допросов, обысков и других следственных мероприятий в ходе предварительного следствия</li>
            <li>посещение клиента в местах заключения под стражу</li>
            <li>участие в судебных заседаниях</li>
            <li>построение линии защиты клиента на различных стадиях уголовного производства (досудебном, в суде первой инстанции, апелляционном суде)</li>
            <li>получение объяснений от свидетелей и дальнейшее приобщение их к делу</li>
            <li>составление процессуальных документов, ходатайств, обжалований, кассаций</li>
            <li>другие необходимые действия со стороны защиты в интересах клиента</li>
          </ul>
          <p><i>Помощь адвоката зачастую необходима и <b>потерпевшей стороне</b> - чтобы взыскать максимальный размер ущерба с подсудимого или убедиться в том, что виновный понес справедливое наказание. Бывает необходимо заставлять правоохранительные органы активно работать, чтобы права потерпевшего были защищены. Консультация адвоката по уголовным делам является отправной точкой в работе потерпевшего с адвокатом.</i></p>
          <p>Ссылка на <b><u><a href="http://www.consultant.ru/document/cons_doc_LAW_10699/" target="_blank" rel="noopener noreferrer">Уголовный кодекс Российской Федерации</a></u></b></p>
        </section>
      </div>
    </div>
  </>
  },
  {
    id: 'administrative-matters',
    value: 'Административные дела',
    element: <>
    <Helmet>
      <title>Административные дела</title>
      <meta property="og:title"
        content="Административные дела" />
      <meta name="description"
        content="Административным правонарушением является нарушение государственных или общественных норм, посягательство на свободу, право или собственность гражданина, а так же принятие незаконных действий или бездействий против него. Наказание, в случае виновности гражданина, представляет собой взыскания: лишения прав, приостановление деятельности организации, арест, предупреждение, административный штраф. Гражданин или юридическое лицо имеет право не доказывать свою невиновность, но в тоже время вправе воспользоваться услугами адвоката по административным делам для более справедливого и наименее затратного для себя исхода административного дела." />
      <link rel="canonical" href="/services/administrative-matters" />
    </Helmet>
    <div className="div-center">
      <div className="max-width-content">
        <section>
          <h3>Административные дела</h3>
          <p>Административным правонарушением является нарушение государственных или общественных норм, посягательство на свободу, право или собственность гражданина, а так же принятие незаконных действий или бездействий против него.</p>
          <p><sup>Правонарушение административного типа не так опасно для общества, как уголовное преступление, но тем не менее может влечь за собой существенный вред для общества.</sup></p>
          <p><i>Наказание, в случае виновности гражданина, представляет собой взыскания: лишения прав, приостановление деятельности организации, арест, предупреждение, административный штраф.</i></p>
          <p>Гражданин или юридическое лицо имеет право не доказывать свою невиновность, но в тоже время вправе воспользоваться услугами адвоката по административным делам для более справедливого и наименее затратного для себя исхода административного дела.</p>
          <p>Например, незаконные действия дорожной полиции и явная предубежденность со стороны суда по делу о дорожно-транспортном правонарушении (ДТП), о нарушении Правил дорожного движения (ПДД), распитии спиртных напитков, курении в общественных местах, несоблюдении санитарно-эпидемиологического благоустройства населения, хищении авторских прав и т.д. – могут очень сильно усложнить нам жизнь.</p>
          <p>И здесь способны помочь только знающие и квалифицированные специалисты. Мы сможем обеспечить надлежащую защиту, разумное наказание для виновного и снятие наказания с невиновного лица.</p>
          <p><u>К услугам адвоката по административным делам относятся:</u></p>
          <ul>
            <li>Консультация;</li>
            <li>Разработка тактики и стратегии;</li>
            <li>Сбор необходимой документации;</li>
            <li>Опрос свидетелей;</li>
            <li>Выявление нарушений в процессуальных действиях;</li>
            <li>Сбор дополнительных доказательств в пользу невиновности клиента;</li>
            <li>Ведение процесса в суде с клиентом и без его участия;</li>
            <li>Проведение процедуры обжалования в вышестоящие органы.</li>
          </ul>
          <p>Ссылка на <b><u><a href="http://www.consultant.ru/document/cons_doc_LAW_34661/" target="_blank" rel="noopener noreferrer">Кодекс Российской Федерации об административных правонарушениях</a></u></b></p>
        </section>
      </div>
    </div>
  </>
  },
  {
    id: 'labor-affairs',
    value: 'Трудовые дела',
    element: <>
    <Helmet>
        <title>Трудовые дела</title>
        <meta property="og:title"
            content="Трудовые дела" />
        <meta name="description"
            content="Российское трудовое законодательство изменчиво, поэтому и работнику, и работодателю жизненно необходимо постоянно находиться в курсе новых веяний в сфере защиты прав сторон в трудовых отношениях. Непонимание друг друга при построении трудовых отношений между сторонами трудового договора зачастую приводят к конфликтам и трудовым спорам, необоснованным нарушениям прав работников. Причиной спора могут стать вопросы применения трудового законодательства, коллективного или трудового договора. Спор может возникнуть как с действующим работником (например, по вопросу оплаты труда или времени отдыха), так и с бывшим работником учреждения (например, по вопросу необоснованного увольнения, задержки выдачи трудовой книжки, невыплаты заработной платы). Нередко работодатель злоупотребляет своим правом сильной стороны в отношениях с работником." />
        <link rel="canonical" href="/services/labor-affairs" />
    </Helmet>
    <div className="div-center">
        <div className="max-width-content">
            <section>
                <h3>Трудовые дела</h3>
                <p>Российское трудовое законодательство изменчиво, поэтому и работнику, и работодателю жизненно необходимо постоянно находиться в курсе новых веяний в сфере защиты прав сторон в трудовых отношениях.</p>
                <p><i>Непонимание друг друга при построении трудовых отношений между сторонами трудового договора зачастую приводят к конфликтам и трудовым спорам, необоснованным нарушениям прав работников.</i></p>
                <p>Причиной спора могут стать вопросы применения трудового законодательства, коллективного или трудового договора. Спор может возникнуть как с действующим работником (например, по вопросу оплаты труда или времени отдыха), так и с бывшим работником учреждения (например, по вопросу необоснованного увольнения, задержки выдачи трудовой книжки, невыплаты заработной платы). Нередко работодатель злоупотребляет своим правом сильной стороны в отношениях с работником.</p>
                <p>Также стоит отметить, что ст. 64 ТК РФ запрещает необоснованно отказывать соискателю в приеме на работу. А это значит, что стороной трудового спора может быть и человек, которому незаконно отказали в трудоустройстве.</p>
                <p>На практике многие руководители уделяют слишком мало внимания трудовым отношениям и продолжают допускать ошибки, за которые приходится расплачиваться как в досудебном порядке (предписания и административные штрафы, налагаемые должностными лицами государственной инспекции труда), так и в суде.</p>
                <p><i>Поэтому услуги адвоката в сфере трудового права могут потребоваться как ИП, юридическому лицу, так и работнику при восстановлении в должности или на работе, взыскании заработной платы, возмещении вреда здоровью и во многих других случаях.</i></p>
                <p><u>В сфере трудового права адвокат предоставляет следующие услуги:</u></p>
                <ul>
                    <li>устные и письменные консультации в сфере трудового законодательства;</li>
                    <li>подготовка документов для переговоров с работодателем, а также для обращения в суд, если вопрос не удастся решить в рамках досудебного урегулирования;</li>
                    <li>представление интересов в суде;</li>
                    <li>экспертиза трудовых договоров;</li>
                    <li>разработка трудовых соглашений;</li>
                    <li>ведение переговоров с профсоюзными органами, трудовой инспекцией и другими государственными организациями;</li>
                    <li>составление жалоб или исков в суд по поводу нарушений Трудового Кодекса;</li>
                    <li>контроль исполнения решения суда.</li>
                </ul>
                <p>Благодаря квалифицированному адвокату по трудовому праву можно всегда решить трудовой спор в свою пользу. Адвокат поможет урегулировать отношения работодателя и работника без обращения в суд.</p>
                <p>Ссылка на <b><u><a href="http://www.consultant.ru/document/cons_doc_LAW_34683/" target="_blank" rel="noopener noreferrer">Трудовой кодекс Российской Федерации</a></u></b></p>
            </section>
        </div>
    </div>
</>
  },
  {
    id: 'family-matters',
    value: 'Семейные дела',
    element: <>
    <Helmet>
        <title>Семейные дела</title>
        <meta property="og:title"
            content="Семейные дела" />
        <meta name="description"
            content="Уникальность семейно-правовых отношений требует особого подхода, и решение различных конфликтных ситуаций в семье без грамотного специалиста может оказаться крайне затруднительным или вовсе невыполнимым. Задача профессионального адвоката по семейным делам, в первую очередь, заключается в стремлении урегулировать отношения между сторонами (супругами, членами семьи) без судебного разбирательства." />
        <link rel="canonical" href="/services/family-matters" />
    </Helmet>
    <div className="div-center">
        <div className="max-width-content">
            <section>
                <h3>Семейные дела</h3>
                <p><i>- У Вас рушится семья и Вы не знаете, как дальше быть ?</i></p>
                <p><i>- Необходимо делить нажитое с супругом имущество ?</i></p>
                <p><i>- В результате раздела имущества с супругом Вы оказались в трудном финансовом положении ?</i></p>
                <p><i>- Вопросы с несовершеннолетними детьми ?</i></p>
                <p>Уникальность семейно-правовых отношений требует особого подхода, и решение различных конфликтных ситуаций в семье без грамотного специалиста может оказаться крайне затруднительным или вовсе невыполнимым. Задача профессионального адвоката по семейным делам, в первую очередь, заключается в стремлении урегулировать отношения между сторонами (супругами, членами семьи) без судебного разбирательства.</p>
                <p><i>Если же без суда не обойтись, то семейный адвокат поможет максимально удовлетворить интересы клиента в суде.</i></p>
                <p><u>Адвокат по семейному праву грамотно и качественно решает такие вопросы, как :</u></p>
                <ul>
                    <li>Бракоразводный процесс</li>
                    <li>Раздел совместно нажитого имущества супругов</li>
                    <li>Дальнейшее взаимоотношение родителей с детьми после развода (проживание, воспитание, алименты и т.д.)</li>
                    <li>Анализ существующего или составление нового брачного договора</li>
                    <li>Алименты на нетрудоспособного члена семьи – жену или мужа</li>
                </ul>
                <p><u>Основными преимуществами работы с опытным адвокатом являются:</u></p>
                <ul>
                    <li>полное консультирование по всем правовым вопросам на основе конкретной ситуации, с прогнозированием каждого действия;</li>
                    <li>представление интересов доверителя в процессе досудебных разбирательств;</li>
                    <li>решение конфликтных ситуаций до суда;</li>
                    <li>составление всей необходимой сопроводительной документации;</li>
                    <li>доказательства той позиции, которую занимает сторона клиента, для рассмотрения в суде;</li>
                    <li>адвокатская защита всех интересов стороны клиента при рассмотрении дела в суде первой инстанции;</li>
                    <li>профессиональное документальное сопровождение при передаче дела в апелляционный и кассационный суды;</li>
                    <li>отслеживание выполнения решения суда исполнительной службой.</li>
                </ul>
                <p>Ссылка на <b><u><a href="http://www.consultant.ru/document/cons_doc_LAW_8982/" target="_blank" rel="noopener noreferrer">Семейный кодекс Российской Федерации</a></u></b></p>
            </section>
        </div>
    </div>
</>
  },
  {
    id: 'hereditary-affairs',
    value: 'Наследственные дела',
    element: <>
    <Helmet>
        <title>Наследственные дела</title>
        <meta property="og:title"
            content="Наследственные дела" />
        <meta name="description"
            content="Если наследодатель заранее побеспокоился и подготовил завещание, то особых сложностей с принятием наследства нет. Хотя нередко бывает, что завещание составлено лицом, не понимающим значения совершенного им действия по составлению завещания в силу определенных заболеваний или состояния здоровья. И тогда есть основания оспорить это завещание в судебном порядке. В случае же, если имущество наследуется по закону (при отсутствии завещания), а на имущество претендует сразу несколько родственников, то часто возникают споры, касающиеся факта принятия наследства, прав наследников либо объема наследственного имущества. Судебный спор растягивается на длительный срок." />
        <link rel="canonical" href="/services/hereditary-affairs" />
    </Helmet>
    <div className="div-center">
        <div className="max-width-content">
            <section>
                <h3>Наследственные дела</h3>
                <p>К сожалению, жизнь быстротечна, и рано или поздно наши близкие и родные умирают, оставляя наследственное имущество и связанные с этим заботы и проблемы.</p>
                <p><i>Если наследодатель заранее побеспокоился и подготовил завещание, то особых сложностей с принятием наследства нет. Хотя нередко бывает, что завещание составлено лицом, не понимающим значения совершенного им действия по составлению завещания в силу определенных заболеваний или состояния здоровья. И тогда есть основания оспорить это завещание в судебном порядке.</i></p>
                <p>В случае же, если имущество наследуется по закону (при отсутствии завещания), а на имущество претендует сразу несколько родственников, то часто возникают споры, касающиеся факта принятия наследства, прав наследников либо объема наследственного имущества. Судебный спор растягивается на длительный срок.</p>
                <p><i>В таких случаях без квалифицированной помощи адвоката не обойтись, поскольку предстоит огромная работа по составлению исковых требований, сбору доказательств и предъявлению их в суде, участию в процессе. Адвокат в сфере наследственного права окажет реальную помощь, что существенно повлияет на исход спорной ситуации.</i></p>
                <p><u>Но не только в таких случаях может понадобиться квалифицированная помощь адвоката, поскольку в практике встречается достаточно много вопросов, когда его услуги необходимы:</u></p>
                <ul>
                    <li>при пропуске сроков, отведенных для принятия наследства;</li>
                    <li>для подтверждения факта родственных отношений с наследодателем;</li>
                    <li>при распределении долей в общей наследственной массе между наследниками.</li>
                </ul>
                <p>Эти и другие вопросы входят в круг услуг адвоката по наследственным правоотношениям, и положительно влияют на исход судебного разбирательства.</p>
                <p><b><u><a href="http://www.consultant.ru/document/cons_doc_LAW_34154/" target="_blank" rel="noopener noreferrer">Гражданский кодекс Российской Федерации, 3 часть</a></u></b></p>
            </section>
        </div>
    </div>
</>
  },
  {
    id: 'parole-lawyer',
    value: 'Условно-досрочное освобождение',
    element: <>
    <Helmet>
        <title>Условно-досрочное освобождение</title>
        <meta property="og:title"
            content="Условно-досрочное освобождение" />
        <meta name="description"
            content="Уголовное законодательство РФ построено таким образом, что лицо осужденное и отбывающее наказание в местах лишения свободы вправе по отбытии установленного законом срока подать в районный суд, по месту отбывания наказания ходатайство об условно-досрочном освобождении. Всегда ли такие ходатайство подлежат удовлетворению или этому имеются препятствия? В последнее время сложилась положительная практика, когда суды, в большинстве обращений их удовлетворяют. Препятствие только в нестабильном положительном поведении осужденного, выраженное в административных правонарушениях связанных с не соблюдением внутреннего распорядка исправительного учреждения." />
        <link rel="canonical" href="/services/parole-lawyer" />
    </Helmet>
    <div className="div-center">
        <div className="max-width-content">
            <section>
                <h3>Условно-досрочное освобождение</h3>
                <p>Уголовное законодательство РФ построено таким образом, что лицо осужденное и отбывающее наказание в местах лишения свободы вправе по отбытии установленного законом срока подать в районный суд, по месту отбывания наказания ходатайство об условно-досрочном освобождении.</p>
                <p>Всегда ли такие ходатайство подлежат удовлетворению или этому имеются препятствия?</p>
                <p>В последнее время сложилась положительная практика, когда суды, в большинстве обращений их удовлетворяют.</p>
                <p>Препятствие только в нестабильном положительном поведении осужденного, выраженное в административных правонарушениях связанных с не соблюдением внутреннего распорядка исправительного учреждения.</p>
                <p>Как правило, осужденные обращаются по истечении 6 месяцев отбывания наказания связанного с лишением свободы, о чем указано в ст. 79 УК РФ.</p>
                <p>Кроме истечения необходимого срока отбывания наказания, стабильного положительного необходимо учитывать и исковые обязательства, связанные с возмещения потерпевшему материального ущерба и морального вреда, судебных расходов.</p>
                <p>В последнее время также суды стараются узнать мнение потерпевшего по поводу данного ходатайства. Возможность удовлетворения ходатайства увеличивается, если потерпевшему возмещен ущерб и моральный вред.</p>
                <p>Знание всех нюансов и особенностей при решении вопроса об условно-досрочном освобождении лица, отбывающего наказание в местах лишения свободы, или вопроса о замене неотбытой части наказания более легким наказанием – позволяет адвокатам намного эффективней решать эти вопросы и оказывать помощь обратившимся к нам гражданам.</p>
            </section>
        </div>
    </div>
</>
  },
  {
    id: 'enforcement-proceedings',
    value: 'Исполнительное производство',
    element: <>
    <Helmet>
        <title>Исполнительное производство</title>
        <meta property="og:title"
            content="Исполнительное производство" />
        <meta name="description"
            content="Судебные приставы сняли зарплату с лицевого счета в Сбербанке. Вызвали Вас повесткой к себе в учреждение, разыскивают ваше имущество! Срочно нужна юридическая помощь! Промедление подобно смерти, законодатель дал Вам всего 10 дней, это срок для принятия мер! Штрафы по линии ГИБДД быстро списывают с лицевого счета. Алиментное обязательство не исполнено, арестовали Ваш автомобиль. Государство обеспокоено собираемостью штрафов, налогов, долгов, для чего были созданы структуры как судебные приставы исполнители и коллекторы." />
        <link rel="canonical" href="/services/enforcement-proceedings" />
    </Helmet>
    <div className="div-center">
        <div className="max-width-content">
            <section>
                <h3>Исполнительное производство</h3>
                <p>Судебные приставы сняли зарплату с лицевого счета в Сбербанке. Вызвали Вас повесткой к себе в учреждение, разыскивают ваше имущество!</p>
                <p>Срочно нужна юридическая помощь!</p>
                <p>Промедление подобно смерти, законодатель дал Вам всего 10 дней, это срок для принятия мер!</p>
                <p>Штрафы по линии ГИБДД быстро списывают с лицевого счета. Алиментное обязательство не исполнено, арестовали Ваш автомобиль.</p>
                <p>Государство обеспокоено собираемостью штрафов, налогов, долгов, для чего были созданы структуры как судебные приставы исполнители и коллекторы.</p>
                <p>Если первые входят в структуру государства, то последние, как правило, бывшие полицейские, работники силовых структур и т.п., маскирующие себя под первыми, для чего дают своим организациям соответствующие названия.</p>
                <p>Судебные приставы работают по возбужденному исполнительному производству, на основании исполнительного листа, выданного судом, мировым судьей.</p>
                <p>По каждому должнику собирают целое досье, исполнительное производство заканчивается таким документом как постановление об окончании исполнительного производства (основание- погашение задолженности, отсутствие у должника имущества, на которое может быть наложено взыскание, иное).</p>
                <p>Помощь квалифицированного юриста поможет Вам сохранить Ваше имущество, Ваши денежные средства, Ваш покой уверенность в завтрашнем дне.</p>
            </section>
        </div>
    </div>
</>
  },
  {
    id: 'accident-lawyer',
    value: 'Дорожно-транспортные происшествия',
    element: <>
    <Helmet>
        <title>Дорожно-транспортные происшествия</title>
        <meta property="og:title"
            content="Дорожно-транспортные происшествия" />
        <meta name="description"
            content="Особенно последнее время законодательство России в сфере безопасности дорожного движения постоянно изменяется, поэтому всем гражданам необходимо знать и правильно понимать действующие Правила дорожного движения. Законодатель приравнял к понятиям: - водитель, не только лиц управляющих транспортными средствами, но и погонщика, ведущего по дороге вьючных, верховых или стадо. К водителю приравнивается лицо, обучающее вождению. - пешеход, лицо, находящееся вне транспортного средства на дороге либо на пешеходной или велосипедной дорожке. Приравниваются к ним лица, передвигающиеся в инвалидной коляске без двигателя, ведущие велосипед, мопед, мотоцикл. - пассажир, лицо кроме водителя, находящееся в транспортном средстве, а также лицо, которое в него входит или выходит." />
        <link rel="canonical" href="/services/accident-lawyer" />
    </Helmet>
    <div className="div-center">
        <div className="max-width-content">
            <section>
                <h3>Дорожно-транспортные происшествия</h3>
                <p>Особенно последнее время законодательство России в сфере безопасности дорожного движения постоянно изменяется, поэтому всем гражданам необходимо знать и правильно понимать действующие Правила дорожного движения.</p>
                <p>Законодатель приравнял к понятиям:</p>
                <p>- водитель, не только лиц управляющих транспортными средствами, но и погонщика, ведущего по дороге вьючных, верховых или стадо. К водителю приравнивается лицо, обучающее вождению.</p>
                <p>- пешеход, лицо, находящееся вне транспортного средства на дороге либо на пешеходной или велосипедной дорожке. Приравниваются к ним лица, передвигающиеся в инвалидной коляске без двигателя, ведущие велосипед, мопед, мотоцикл.</p>
                <p>- пассажир, лицо кроме водителя, находящееся в транспортном средстве, а также лицо, которое в него входит или выходит.</p>
                <p>Почти каждый человек в своей жизни изучал, читал Правила дорожного движения, но как показывает практика этого явно недостаточно по вышесказанной причине. Кроме того, нередки случаи, когда и сотрудники ИДПС допускают неверное толкование этого закона, что в конечном счете может стать причиной необоснованного привлечения к административной и даже уголовной ответственности того или иного участника дорожного движения. Каждый день мы становимся участниками дорожного движения, а потому всякий раз на дороге нас подстерегает опасность столкновения автомобилей или наезда на пешехода. Любой участник дорожного движения думает, что беда его всегда минует, но именно незнание правил дорожного движения, их не соблюдение и становится причиной неблагоприятных последствий.</p>
                <p>Сразу скажу, что уголовные преступления, предусмотренные ст. 264 УК РФ относятся к преступлениям трудно раскрываемым, хотя участники, как правило, известны, если один из них не скрылся с места происшествия. Основная причина состоит в том, чтобы место преступления (схема и протокол осмотра места происшествия) были оформлены в соответствии с точным расположением не только дорожных знаков, разметки, но и транспортных средств, а также сломанных частей от транспортных средств. Причиной может также стать техническая неисправность транспортного средства или иное. В таком случае важно скорее обратиться к компетентному лицу за помощью. Только таким способом возможно объективно и законно предотвратить нарушения своих прав на справедливое разбирательство дела, как в полиции, так и в суде.</p>
            </section>
        </div>
    </div>
</>
  },
  {
    id: 'consumer-protection',
    value: 'Защита прав потребителей',
    element:  <>
    <Helmet>
        <title>Защита прав потребителей</title>
        <meta property="og:title"
            content="Защита прав потребителей" />
        <meta name="description"
            content="Правильно и вовремя сделанные шаги для исправления положения позволят не только возвратить затраченные Вами средства, но и соответствующим образом наказать нерадивую организацию, оказывающую некачественные услуги и продающую некачественный товар. Закон позволяет взыскивать в пользу потребителя не только размер Ваших понесенных расходов, но и дополнительные суммы – неустойку, штраф, компенсацию морального вреда." />
        <link rel="canonical" href="/services/consumer-protection" />
    </Helmet>
    <div className="div-center">
        <div className="max-width-content">
            <section>
                <h3>Защита прав потребителей</h3>
                <p>Каждый день мы с вами становимся участниками правоотношений по заказу, приобретению, реализации множества товаров и услуг, предлагаемых различными предпринимателями, организациями для неограниченного круга лиц: покупаем, зайдя после работы в магазин, продукты питания, приобретаем предметы одежды, покупаем дорогостоящие технически сложные товары – компьютеры, холодильники, телевизоры, мотоциклы, автомашины, моторные лодки и т.д., меняем входные двери в квартиру, устанавливаем пластиковые окна, заказываем ремонтные работы дома, на даче, лечим зубы и заключаем договора на протезирование, и т.д.</p>
                <p>Как мы расстраиваемся, когда выясняется, что товар мы приобрели некачественный, что оказанная нам услуга имеет недостатки. Что делать в таком случае ?</p>
                <p>Только грамотный квалифицированный адвокат сможет оказать Вам помощь в такой ситуации, справиться с проблемой в кратчайшие сроки и с минимальными потерями для Вашего настроения, здоровья и благополучия.</p>
                <p>Правильно и вовремя сделанные шаги для исправления положения позволят не только возвратить затраченные Вами средства, но и соответствующим образом наказать нерадивую организацию, оказывающую некачественные услуги и продающую некачественный товар. Закон позволяет взыскивать в пользу потребителя не только размер Ваших понесенных расходов, но и дополнительные суммы – неустойку, штраф, компенсацию морального вреда.</p>
                <p>Ссылка на <b><u>
                    <a href="http://www.consultant.ru/document/cons_doc_LAW_305/" target="_blank" rel="noopener noreferrer">Закон Российской Федерации «О защите прав потребителей»</a>
                </u></b></p>
            </section>
        </div>
    </div>
</>
  },
  {
    id: 'housing-issues',
    value: 'Жилищные вопросы',
    element: <>
    <Helmet>
        <title>Жилищные вопросы</title>
        <meta property="og:title"
            content="Жилищные вопросы" />
        <meta name="description"
            content="Все мы являемся владельцами жилых помещений, проживаем в комнатах, квартирах, жилых домах или коттеджах, и, соответственно, пользуемся в той или иной степени правами, предоставляемыми жилищным законодательством каждому гражданину. Как часто наши права собственников, пользователей жилых помещений нарушаются представителями коммунальных служб, государственных структур, нашими соседями, знакомыми и незнакомыми нам лицами." />
        <link rel="canonical" href="/services/housing-issues" />
    </Helmet>
    <div className="div-center">
        <div className="max-width-content">
            <section>
                <h3>Жилищные вопросы</h3>
                <p>Все мы являемся владельцами жилых помещений, проживаем в комнатах, квартирах, жилых домах или коттеджах, и, соответственно, пользуемся в той или иной степени правами, предоставляемыми жилищным законодательством каждому гражданину.</p>
                <p><i>Как часто наши права собственников, пользователей жилых помещений нарушаются представителями коммунальных служб, государственных структур, нашими соседями, знакомыми и незнакомыми нам лицами.</i></p>
                <p>Неоценимой является помощь адвокатов при разрешении судебных споров, касающихся жилищного вопроса, защите прав собственников и прав пользователей жилыми помещениями.</p>
                <p><u>Вот лишь краткий список споров, разрешаемых с помощью компетентных юристов:</u></p>
                <ul>
                    <li>Дела, касающиеся улучшения жилищных условий;</li>
                    <li>Признание права собственности или права пользования жилым помещением;</li>
                    <li>Вселение в квартиру или выселение из квартиры;</li>
                    <li>Определение порядка пользования жилым помещением;</li>
                    <li>Заключение и расторжение договора найма;</li>
                    <li>Вопрос о предоставлении социального жилья;</li>
                    <li>и т.д.</li>
                </ul>
                <p>Ссылка на <b><u>
                    <a href="http://www.consultant.ru/document/cons_doc_LAW_51057/" target="_blank" rel="noopener noreferrer">Жилищный кодекс Российской Федерации</a>
                </u></b></p>
            </section>
        </div>
    </div>
</>
  },
  {
    id: 'estate-lawyer',
    value: 'Дела по недвижимости',
    element: <>
    <Helmet>
        <title>Дела по недвижимости</title>
        <meta property="og:title"
            content="Дела по недвижимости" />
        <meta name="description"
            content="Совершение любых операций с недвижимостью и установление правовых отношений граждан в данной сфере зачастую требуют обращения к услугам адвоката. Заключение сделок в отношении квартир, жилых домов, земельных участков требуют внимания и осторожности, чтобы не оказаться в результате ни с чем. Допущенные ошибки при заключении сделок с недвижимостью, нарушение законодательства со стороны контрагента позволяют защитить права и интересы потерпевшей стороны в суде, вернуть все на свои места. Знание нюансов требований гражданского законодательства, особенностей совершения каждого вида сделок позволяет профессиональному адвокату оказать надлежащую помощь обратившемуся к нему человеку. " />
        <link rel="canonical" href="/services/estate-lawyer" />
    </Helmet>
    <div className="div-center">
        <div className="max-width-content">
            <section>
                <h3>Дела по недвижимости</h3>
                <p>Совершение любых операций с недвижимостью и установление правовых отношений граждан в данной сфере зачастую требуют обращения к услугам адвоката. Заключение сделок в отношении квартир, жилых домов, земельных участков требуют внимания и осторожности, чтобы не оказаться в результате ни с чем.</p>
                <p>Допущенные ошибки при заключении сделок с недвижимостью, нарушение законодательства со стороны контрагента позволяют защитить права и интересы потерпевшей стороны в суде, вернуть все на свои места .</p>
                <p>Знание нюансов требований гражданского законодательства, особенностей совершения каждого вида сделок позволяет профессиональному адвокату оказать надлежащую помощь обратившемуся к нему человеку.</p>
                <p>В каком же случае могут понадобиться услуги адвоката в сфере недвижимости? При спорах о заключении или расторжении сделок в отношении недвижимым имуществом, спорах о праве собственности, спорах о долевом строительстве, при рассмотрении ненадлежащего исполнения строительных работ, спорах о незаконном использовании земельного участка и т.д.</p>
                <p>При возникновении спорной ситуации следует помнить, что спектр нормативных актов очень широк, и разобраться во всех законах самостоятельно, без юридического образования и знаний бывает крайне сложно. Поэтому стоит вовремя обратиться к профессионалу, который обеспечит грамотное составление документации, проследит за изменением законов, тщательно изучит документацию, соберет все доказательства и грамотно защитит вашу сторону в суде. От этого напрямую зависит благополучие разрешения спора.</p>
            </section>
        </div>
    </div>
</>
  },
  {
    id: 'credit-affairs',
    value: 'Кредитные дела',
    element: <>
    <Helmet>
        <title>Кредитные дела</title>
        <meta property="og:title"
            content="Кредитные дела" />
        <meta name="description"
            content="Все мы в процессе нашей жизни сталкиваемся с необходимостью получения крупных и не очень денежных сумм в кредит в кредитных организациях. Зачастую банки при заключении кредитного договора навязывают нам свои неудобные, и даже кабальные условия, заставляя оплачивать большие суммы за ненужную нам страховку или комплекс банковских услуг. А когда мы не в силах справляться с выполнением навязанных нам услуг, банки продают образовавшиеся долги по кредитным договорам коллекторским агентствам, не имеющим лицензии на осуществление банковских услуг и занимающихся выколачиванием долгов в неправовом поле." />
        <link rel="canonical" href="/services/credit-affairs" />
    </Helmet>
    <div className="div-center">
        <div className="max-width-content">
            <section>
                <h3>Кредитные дела</h3>
                <p><i>Услуги профессионального адвоката в банковской и финансовой сферах могут понадобиться на любой стадии взаимоотношений между банком и его клиентом, при заключении кредитного договора или его расторжении, снятии или уменьшении размера задолженности по кредиту.</i></p>
                <p>Все мы в процессе нашей жизни сталкиваемся с необходимостью получения крупных и не очень денежных сумм в кредит в кредитных организациях. Зачастую банки при заключении кредитного договора навязывают нам свои неудобные, и даже кабальные условия, заставляя оплачивать большие суммы за ненужную нам страховку или комплекс банковских услуг.</p>
                <p><i>А когда мы не в силах справляться с выполнением навязанных нам услуг, банки продают образовавшиеся долги по кредитным договорам коллекторским агентствам, не имеющим лицензии на осуществление банковских услуг и занимающихся выколачиванием долгов в неправовом поле.</i></p>
                <p>В услуги адвоката входят разные направления: работа и деятельность финансовых учреждений, консультирование по разным вопросам, связанным с банковской и финансовой деятельностью, защита прав и интересов клиентов банка, противодействие незаконным нападкам коллекторов и многое другое.</p>
                <p>От работы адвоката напрямую зависит исход любого дела. Во время судебного разбирательства работа адвоката помогает получить необходимую информацию, которая помогает в принятии решения. Для каждой стороны правильное предоставление доказательств адвокатом является серьёзным преимуществом, которое окажет положительное влияние на исход дела. Также стоит исходить из многочисленных нюансов и тонкостей в финансовом, банковском и валютном направлении. Разобрать в этих тонкостях помогает профессиональный адвокат.</p>
            </section>
        </div>
    </div>
</>
  },
  {
    id: 'disputes-in-arbitration',
    value: 'Споры в Арбитраже',
    element: <>
    <Helmet>
        <title>Споры в Арбитраже, мирное разрешение cпоров</title>
        <meta property="og:title"
            content="Споры в Арбитраже, мирное разрешение cпоров" />
        <meta name="description"
            content="При наличии экономических споров между хозяйствующими субъектами, индивидуальными предпринимателями или юридическими лицами, зачастую неизбежно обращение в Арбитражный суд, где также не обойтись без помощи грамотного, сведущего в различных отраслях гражданского права адвоката. При осуществлении судебно-арбитражной практики, под которой принято подразумевать отдельные решения судейских коллегий, задействованных в качестве источников права, возможны и мирные разрешения споров, принятые в порядке процедуры, именуемой - Медиация." />
        <link rel="canonical" href="/services/disputes-in-arbitration" />
    </Helmet>
    <div className="div-center">
        <div className="max-width-content">
            <section>
                <h3>Споры в Арбитраже, мирное разрешение cпоров</h3>
                <p><i>При наличии экономических споров между хозяйствующими субъектами, индивидуальными предпринимателями или юридическими лицами, зачастую неизбежно обращение в Арбитражный суд, где также не обойтись без помощи грамотного, сведущего в различных отраслях гражданского права адвоката.</i></p>
                <p>При осуществлении судебно-арбитражной практики, под которой принято подразумевать отдельные решения судейских коллегий, задействованных в качестве источников права, возможны и мирные разрешения споров, принятые в порядке процедуры, именуемой - Медиация.</p>
                <p><i>Медиация, по сути - это обыкновенный переговорный процесс, реализующийся при помощи третьего лица, именуемого медиатором. Главный принцип заключается в том, что стороны, задействованные в конфликте, самостоятельно принимают единое решение, удовлетворяющее позиции обеих сторон.</i></p>
                <p>Прибегнуть к адвокатской помощи при осуществлении данной процедуры рекомендуется, поскольку:</p>
                <ul>
                    <li>это обеспечит защиту от недостоверной информации со стороны противоположной стороны (что является ключевым аргументом в пользу услуг профессионального адвоката),</li>
                    <li>профессиональный анализ позволит выявить риски,</li>
                    <li>представит надежный фундамент для оспаривания итогового решения,</li>
                    <li>это поспособствует выстраиванию конструктивных переговоров.</li>
                </ul>
            </section>
        </div>
    </div>
</>
  },
  {
    id: 'protection-of-honor-and-dignity',
    value: 'Защита чести и достоинства',
    element: <>
    <Helmet>
        <title>Защита чести и достоинства</title>
        <meta property="og:title"
            content="Защита чести и достоинства" />
        <meta name="description"
            content="Все более востребованными становятся по вопросам защиты своей чести и достоинства, дела соответствующей категории увеличиваются год от года в судах разных инстанций. Что понимается под терминами «честь», «достоинство», «деловая репутация»? Честь – оценка личности обществом, базирующаяся на социальных и духовных чертах данного человека. Под достоинством же понимается самооценка, то есть мнение персоны о себе, как о личности. Деловая репутация – термин, применяемый не только к юридическим, но и физическим лицам, когда учитываются профессиональные и личностные качества в целом." />
        <link rel="canonical" href="/services/protection-of-honor-and-dignity" />
    </Helmet>
    <div className="div-center">
        <div className="max-width-content">
            <section>
                <h3>Защита чести и достоинства</h3>
                <p>Все более востребованными становятся по вопросам защиты своей чести и достоинства, дела соответствующей категории увеличиваются год от года в судах разных инстанций.</p>
                <p><u>Что понимается под терминами «честь», «достоинство», «деловая репутация»?</u></p>
                <p><dd><b>Честь</b> – оценка личности обществом, базирующаяся на социальных и духовных чертах данного человека.</dd></p>
                <p><dd>Под <b>достоинством</b> же понимается самооценка, то есть мнение персоны о себе, как о личности.</dd></p>
                <p><dd><b>Деловая репутация</b> – термин, применяемый не только к юридическим, но и физическим лицам, когда учитываются профессиональные и личностные качества в целом.</dd></p>
                <p><i>Если о Вас распространили несоответствующие действительности сведения порочащего характера на работе, по месту жительства либо в средствах массовой информации, и Вы хотите восстановить справедливость, защитить свое честное имя - тогда Вам необходима помощь адвоката.</i></p>
                <p><u>Адвокат, специализирующийся на защите чести, достоинства и деловой репутации, поможет:</u></p>
                <ul>
                    <li>публично опровергнуть распространенную в отношении Вас порочащую информацию;</li>
                    <li>предотвратить ее дальнейшее распространение;</li>
                    <li>изъять из гражданского оборота все экземпляры информационных носителей с такого рода сведениями;</li>
                    <li>добиться компенсации убытков (возможных) и морального вреда, нанесенного его доверителю.</li>
                </ul>
            </section>
        </div>
    </div>
</>
  },
  {
    id: 'land-affairs',
    value: 'Земельные дела',
    element: <>
    <Helmet>
        <title>Земельные дела</title>
        <meta property="og:title"
            content="Земельные дела" />
        <meta name="description"
            content="Адвокат в сфере земельного права поможет квалифицированно разрешить спорные вопросы, и отстоять нарушенные права доверителя. Земельное право – специфическая отрасль правоотношений в системе действующего законодательства. Без знаний отдельных норм и положений, единолично разрешить спорную ситуацию практически невозможно." />
        <link rel="canonical" href="/services/land-affairs" />
    </Helmet>
    <div className="div-center">
        <div className="max-width-content">
            <section>
                <h3>Земельные дела</h3>
                <p>Адвокат в сфере земельного права поможет квалифицированно разрешить спорные вопросы, и отстоять нарушенные права доверителя.</p>
                <p><i><dd>Земельное право – специфическая отрасль правоотношений в системе действующего законодательства. Без знаний отдельных норм и положений, единолично разрешить спорную ситуацию практически невозможно.</dd></i></p>
                <p><u>Существует немало вопросов, связанных с установлением либо реализацией прав на земельные участки, когда помощь профессионала- адвоката просто необходима:</u></p>
                <ul>
                    <li>выдел земельного участка в натуре, его раздел;</li>
                    <li>определение границ земельного участка;</li>
                    <li>землеотвод и подготовка документов для строительства;</li>
                    <li>узаконивание самовольного строительства либо реконструированного домовладения;</li>
                    <li>изменение целевого назначения земли.</li>
                </ul>
                <p><i>Эти, и многие другие, вопросы требуют профессиональных знаний и компетентности, и без участия адвоката в сфере земельного права можно не достичь желаемого результата.</i></p>
                <p><u>Алгоритм действий адвоката по земельным спорам:</u></p>
                <ul>
                    <li>правовой анализ конкретной ситуации;</li>
                    <li>подготовка документов и составление искового заявления для суда;</li>
                    <li>подача документов и представление интересов доверителя в судебном процессе;</li>
                    <li>сопровождение на стадии исполнительного производства.</li>
                </ul>
                <p>Ссылка на <b><u>
                    <a href="http://www.consultant.ru/document/cons_doc_LAW_33773/" target="_blank" rel="noopener noreferrer">Земельный кодекс Российской Федерации</a>
                </u></b></p>
            </section>
        </div>
    </div>
</>
  }
];